<div class="teachers-container">
  <h2 class="teachers-title">
    Our Teachers
  </h2>
  <p class="teachers-words">Our Teachers excel in all subjects and are really keen on their students' success. We are really proud to present
    them to you.</p>
  <div class="teachers-grid">
    <app-teacher-tile *ngFor="let teacher of teachers" [teacherName]="teacher.teacherName" [subject]="teacher.subject" [teacherImage]="teacher.imageUrl"></app-teacher-tile>
    <app-teacher-tile [teacherName]="lastTeacher.teacherName" [subject]="lastTeacher.subject" [teacherImage]="lastTeacher.imageUrl"></app-teacher-tile>
  </div>
</div>