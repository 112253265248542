import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from "@angular/common";
import { VideosService } from 'src/app/services/videos.service';
import { BackendError } from 'src/app/models/error.model';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Video } from 'src/app/models/video.model';
import { Player } from '@vime/angular';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  embedLink = "";
  title = "";
  video: Video = null;
  hasError: boolean = false;

  errorMessage: string = "";
  errorName: string = "";
  errorImage: string = "";

  isLoading: boolean = false;
  canRetry: boolean = false;
  canView: boolean = false;

  isHLS: boolean = false;

  codeBehvaiourSubject: BehaviorSubject<string>;

  @ViewChild('player', { static: false }) player!: Player;

  constructor(private _location: Location, private _videoService: VideosService, private _route: ActivatedRoute) {
    this.createBehaviourSubject();
  }

  ngOnInit() {
    let code = this._route.snapshot.params.code;
    this.getVideo(code);
  }

  async getVideo(code: string) {
    this.isLoading = true;
    this.hasError = false;
    this.canRetry = false
    this._videoService.getVideoFromCode(code).then((video) => {
      console.log(video)
      this.embedLink = video.videoLink;
      this.title = video.videoTitle;
      this.video = video;
      this.canView = true;
      this.processQuizLink();
    }).catch((err: BackendError) => {
      this.hasError = true;
      this.errorMessage = err.message;
      this.errorName = err.name;
      this.processError();
    }).finally(() => {
      this.isLoading = false;
    })
  }

  goBack() {
    this._location.back();
  }

  processError() {
    if (this.errorMessage.includes('no such code')) {
      this.errorMessage = "Video Code not found. Perhaps, you might try again";
      this.errorImage = "assets/svg/2663518.svg";
      this.canRetry = true;
      return;
    } else if (this.errorMessage.includes('limit')) {
      this.errorMessage = "You have exceeded watch limit on this video"
    } else if (this.errorMessage.includes('device')) {
      this.errorMessage = "You have watched this video on a different device";
    }
    this.errorImage = "assets/svg/2810644.svg";
  }

  createBehaviourSubject() {
    if (this.codeBehvaiourSubject == null) {
      this.codeBehvaiourSubject = new BehaviorSubject<string>(null);
      this.codeBehvaiourSubject.subscribe((s) => {
        if (!s) {
          return;
        }
        this.getVideo(s);
      })
    }
  }

  async goToQuiz() {
    if (this.video.quizLink == null || this.video.quizLink.length == 0) {
      return;
    }
    window.open(this.video.quizLink, '_quiz')
    await this._videoService.takeQuiz(this._route.snapshot.params.code);
  }

  async goToRecording() {
    if (this.video.recordingLink == null || this.video.recordingLink.length == 0) {
      return;
    }
    window.open(this.video.recordingLink, '_rec')
  }

  private processQuizLink() {
    this.isHLS = this.embedLink.includes('m3u8');
  }

}
