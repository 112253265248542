<div class="header">
  <div class="grid-item left-grid">
    <a href="#" class="header-link">Home</a>
    <a href="#teachers" class="header-link">Teachers</a>
    <a href="#about" class="header-link">About Us</a>
  </div>
  <div class="grid-item no-mobile" ></div>
  <div class="grid-item no-mobile"></div>
  <div class="grid-item right-grid">
    
    <code-button *ngIf="enableCode" [codeSubject]='this.codeBehaviourSubject'></code-button>
    <button *ngIf="!enableCode" mat-flat-button color="black" (click)="downloadApp()">Download App</button>
  </div>
</div>