import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Video } from '../models/video.model';
import { FingerprintService } from './fingerprint.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn : "root"
})
export class VideosService {
    
    constructor(
        private _httpClient : HttpClient,
        private _fingerprintService : FingerprintService
    ) {

    }

    async getVideoFromCode(code : string) : Promise<Video> {
        let fingerprint = await this._fingerprintService.getFingerprint();
        try{
            code = code.toUpperCase();
            let video : any = await this._httpClient.get(`${environment.backend_url}/videos/codes?code=${code}&mac-address=${fingerprint}`).toPromise();
            console.log(video)
            return Promise.resolve({
                videoTitle : video.videoTitle,
                videoLink : video.videoLink,
                quizTitle : video.quizTitle,
                quizLink : video.quizLink,
                zoomLink : video.zoomLink,
                recordingLink : video.recordingLink,
                recordingTitle : video.recordingTitle
            });
        }catch(error) {
            return Promise.reject({
                name : error.error.name,
                message : error.error.message
            });
        }
        
    }

    async takeQuiz(code : string) {
        code = code.toUpperCase();
        try {
            await this._httpClient.post(`${environment.backend_url}/quizzes/${code}`, {}).toPromise();
        } catch(e) {
            return Promise.reject({
                name : e.error.name,
                message : e.error.message
            })
        }
    }
}