import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-first-section',
  templateUrl: './first-section.component.html',
  styleUrls: ['./first-section.component.scss']
})
export class FirstSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openForm() {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSfM88NBossc8rU3Hq6624IAvGxenTaweCwUVit9gGb2oRa1wA/viewform?fbclid=IwAR0P9VCbP65nM-juNBe3Xq9pJZPlq3qPiXuND4wc2xIxNtYu-hzTiMYCPzs", "_register");
  }

}
