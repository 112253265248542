import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-teacher-tile',
  templateUrl: './teacher-tile.component.html',
  styleUrls: ['./teacher-tile.component.scss']
})
export class TeacherTileComponent implements OnInit {
  @Input('teacherImage') image : string;
  @Input('teacherName') name : string;
  @Input('subject') subject : string;
  constructor() { }

  ngOnInit() {
  }

}
