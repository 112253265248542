<div class="footer">
  <div class="left-part">
    <h2>
      DH Online Center
    </h2>
  </div>
  <div class="middle-part">
    <a href="https://www.facebook.com/DHL-Center-Maadi-227550487645598/" target="_facebook" class="fa fa-facebook"></a>
  </div>
  <div class="right-part">
    <h3>
      Mobile Number : +201011263327
    </h3>
    <h3>
      العنوان : 112 شارع اللاسلكي الصغير أمام قنديل مصر و خلف رضوان العجيل
    </h3>
  </div>
</div>