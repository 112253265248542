import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'code-button',
  templateUrl: './code-button.component.html',
  styleUrls: ['./code-button.component.scss']
})
export class CodeButtonComponent implements OnInit {
  @Input('codeSubject') codeBehaviourSubject : BehaviorSubject<string>;

  expand = false;
  videoCode = new FormControl('', [Validators.required, Validators.minLength(8)]);
  constructor() {

  }

  ngOnInit() {
    this.videoCode.valueChanges.subscribe(val => {
      if(val.length == 8){
        //Should get video Id first
        this.goToVideo();
      }
    }); 
  }
  
  goToVideo() {
    if(!this.videoCode.valid){
      return;
    }
    if(this.codeBehaviourSubject != null) {
      this.codeBehaviourSubject.next(this.videoCode.value);
    }
    // this._router.navigate([`/video/${this.videoCode.value}`]);
  }
}
