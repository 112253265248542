<vg-player>
    <vg-overlay-play></vg-overlay-play>
    <vg-buffering></vg-buffering>

    <vg-scrub-bar>
        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
        <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>
    <video #vgHLS (onGetBitrates)="this.getBitrates($event)" [vgHls]="this.embedLink" [vgMedia]="media" #media
        id="singleVideo" preload="auto" crossorigin></video>
    <vg-controls>
        <vg-play-pause></vg-play-pause>
        <vg-playback-button></vg-playback-button>

        <vg-time-display vgProperty="current" vgFormat="hh:mm:ss"></vg-time-display>

        <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

        <vg-time-display vgProperty="left" vgFormat="hh:mm:ss"></vg-time-display>
        <!-- <vg-time-display vgProperty="total" vgFormat="hh:mm:ss"></vg-time-display> -->

        <vg-quality-selector *ngIf="hlsBitrates" (onBitrateChange)="setBitrate($event)" [bitrates]="hlsBitrates">
        </vg-quality-selector>
        <vg-mute></vg-mute>
        <vg-volume></vg-volume>

        <vg-fullscreen></vg-fullscreen>
    </vg-controls>
</vg-player>