<div class="header">
  <mat-icon (click)="goBack()">keyboard_arrow_left</mat-icon>
</div>

<div class="loader-container" *ngIf="isLoading">
  <mat-spinner [strokeWidth]='5'></mat-spinner>
</div>


<div *ngIf="!isLoading && canView" class="video-facade-container">
  <div class="video-container" *ngIf="this.embedLink.length != 0">
    <iframe *ngIf="!this.isHLS" class="video-viewer" [src]="embedLink | safe" frameborder="0" webkitallowfullscreen
      mozallowfullscreen allowfullscreen></iframe>
    <!-- <video-player class="actual-player" [title]='title' [link]='this.embedLink'></video-player> -->
    <h1 class="video-title">{{title}}</h1>
  </div>
  <div class="quiz-requirement" *ngIf="this.embedLink.length == 0">
    <h2>You have to take the quiz, before watching the video</h2>
  </div>
  <div>
  </div>
</div>

<div class="error-container" *ngIf="!isLoading && hasError">
  <img class="error-image" [src]='this.errorImage' />
  <h2 class="error-text">{{errorMessage}}</h2>
  <code-button [codeSubject]='this.codeBehvaiourSubject' *ngIf="canRetry"></code-button>
</div>