import * as Fingerprint2 from "fingerprintjs2";
import { Injectable } from '@angular/core';

@Injectable({
    providedIn : 'root'
})
export class FingerprintService {
    getFingerprint(): Promise<string> {
        return new Promise((resolve, reject) => {
            Fingerprint2.getV18((murmur, components) => {
                resolve(murmur);
            });
        });
    }
}