import { Component, OnInit } from '@angular/core';

import { Teacher } from "../../models/teacher.model";

@Component({
  selector: 'app-teachers',
  templateUrl: './teachers.component.html',
  styleUrls: ['./teachers.component.scss']
})
export class TeachersComponent implements OnInit {

  teachers : Teacher[] = [
    new Teacher("Ahmed Rashad", "Math", "assets/teachers/rashad.jpeg"),
    new Teacher("Farid Shawky", "Arabic" , "assets/teachers/farid.jpeg"),
    new Teacher("Mohamed Salah", "Physics", "assets/teachers/salah.jpeg"),
    new Teacher("Monsieur Choukry", "French", "assets/teachers/choukry.jpeg"),
    new Teacher("Mahdy Tahtawy", "English", "assets/teachers/tahtawy.jpeg"),
    new Teacher("Maged Salib", "German", "assets/teachers/salib.jpeg"),
    new Teacher("Dr.Hossam Gedawy", "Biology", "assets/teachers/gedawy.jpeg"),
    new Teacher("Dr.Tarek Essam", "Chemistry", "assets/teachers/tarek_essam.jpeg"),
    new Teacher("Sameh Nashaat", "Geology", "assets/teachers/nashaat.jpeg"),
    new Teacher("Frau Asmaa", "German", "assets/teachers/asmaa.jpg"),
    new Teacher("Mohamed Osman", "Philoshophical Subjects", "assets/teachers/othman.jpeg")
  ];
  lastTeacher : Teacher = new Teacher("Dr.Nasser Batal", "Chemistry", "assets/teachers/nasser_batal.jpeg");

  constructor() { }

  ngOnInit() {
  }

}
