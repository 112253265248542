import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { DevicesService } from 'src/app/services/devices.service';

@Component({
  selector: 'dhl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  codeBehaviourSubject: BehaviorSubject<string>;

  enableCode: boolean = false;
  constructor(private _router: Router, private _deviceService: DevicesService) {

  }

  ngOnInit() {
    this.codeBehaviourSubject = new BehaviorSubject<string>(null);
    this.codeBehaviourSubject.subscribe((code) => {
      if (!code) {
        return;
      }
      if (code.length != 8) {
        return;
      }
      this._router.navigate([`/video/${code}`]);
    })
    this.enableCode = this._deviceService.isIOS() || this._deviceService.isElectron() || this._deviceService.isAndroid();
  }

  downloadApp() {
    if(this._deviceService.isAndroid()) {
      window.open("https://play.google.com/store/apps/details?id=com.eshra7ly.dh_mobile", "_android");
    }else {
      window.open('https://drive.google.com/file/d/1sMwBiOZIS4lFBFKOcH_LROQ6Tkd10zHk/view?usp=sharing', '_windowsApp')
    }
    
  }
}
