<div class="omar-header">

  <div class="right">
    <div class="card">
      <img src="assets/omar.jpeg" alt="Avatar" style="width:100%; height: 500px; object-fit: cover;">
      <div class="container">
        <h4><b>Omar Ahmed</b></h4>
        <p class="about-omar">Combining fun with knowledge since 2016. Omar doesn't only make physics easy, he makes it
          fun. Forming incredible bonds with students even after their exams are over.</p>
      </div>
    </div>
  </div>

  <div class="left">
    <div class="trusted-container">
      <img src="assets/omar-logo-new.jpg" height="250px" />
      <code-button [codeSubject]='this.codeBehaviourSubject'></code-button>
      <hr />
      <p>Trusted by</p>
      <div class="images-grid">
        <img src="assets/gbs.jpeg" height="150px" />
        <img src="assets/bashaer.png" height="150px" />
        <img src="assets/summits.jpg" height="150px" />
        <img src="assets/stars.png" height="150px" />
        <img src="assets/kis.jpg" height="150px" />
        <img src="assets/noor.jpeg" height="150px"/>
        <img src="assets/eshra7ly.png" height="100px" />
      </div>
    </div>
  </div>

</div>