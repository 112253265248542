import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { TeachersComponent } from './components/teachers/teachers.component';
import { LandingComponent } from './components/landing//landing.component';
import { VideoComponent } from './components/video/video.component';
import { TeacherTileComponent } from './components/teacher-tile/teacher-tile.component';
import { CodeButtonComponent } from './components/code-button/code-button.component';
import { HeaderComponent } from './components/header/header.component';
import { FirstSectionComponent } from './components/first-section/first-section.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { OmarHomeComponent } from './components/omar-home/omar-home.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';

import { SafePipe } from './config/safe.pipe';
import { HttpClientModule } from '@angular/common/http';

import { VideosService } from './services/videos.service';
import { FingerprintService } from './services/fingerprint.service';
import { DevicesService } from './services/devices.service';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgStreamingModule } from "@videogular/ngx-videogular/streaming";
@NgModule({
  declarations: [
    AppComponent,
    CodeButtonComponent,
    HeaderComponent,
    FirstSectionComponent,
    TeachersComponent,
    LandingComponent,
    VideoComponent,
    TeacherTileComponent,
    AboutUsComponent,
    SafePipe,
    OmarHomeComponent,
    VideoPlayerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule
  ],
  providers: [
    VideosService,
    FingerprintService,
    DevicesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
