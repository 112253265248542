import { Injectable } from '@angular/core';
import detectElectron from "is-electron";

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  constructor() { }

  isIOS() {
    let w : any = window;
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !w.MSStream;
  }

  isElectron() {
    return detectElectron();
  }

  isAndroid() {
    return /(android)/i.test(navigator.userAgent);
  }

  isPC() {
    return !this.isIOS() && !this.isAndroid() && !this.isElectron();
  }
  
}
