<div class="courses-container">
  <div class="course">
    <div class="course-info">
      <h6>{{subject}}</h6>
      <h2>{{name}}</h2>
    </div>
    <div class="course-preview">
      <img [src]='image'>
    </div>
  </div>
</div>