import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LandingComponent } from './components/landing//landing.component';
import { OmarHomeComponent } from './components/omar-home/omar-home.component';
import { VideoComponent } from './components/video/video.component';
import { PlatformGuard } from './platform.guard';

const routes: Routes = [
  {
    path: '',
    component: OmarHomeComponent
  },
  {
    path: 'video/:code',
    component: VideoComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
