export class Teacher {
    teacherName : string;
    subject : string;
    imageUrl : string;

    constructor(name : string, subject : string, picture : string) {
        this.teacherName = name;
        this.subject = subject;
        this.imageUrl = picture;
    }
}