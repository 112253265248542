import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-omar-home',
  templateUrl: './omar-home.component.html',
  styleUrls: ['./omar-home.component.scss']
})
export class OmarHomeComponent implements OnInit {
  codeBehaviourSubject: BehaviorSubject<string>;

  constructor(private _router: Router, private _title : Title) { }

  ngOnInit() {
    this._title.setTitle('Omar Ahmed Eshra7ly')
    this.codeBehaviourSubject = new BehaviorSubject<string>(null);
    this.codeBehaviourSubject.subscribe((code) => {
      if (!code) {
        return;
      }
      if (code.length != 8) {
        return;
      }
      this._router.navigate([`/video/${code}`]);
    })
  }

}
