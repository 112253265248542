<div class="button-container">
	<button mat-flat-button color="black" *ngIf="!expand" (click)="expand = true">Enter your code</button>
</div>

<div class="form-container" *ngIf="expand">
	<mat-form-field >
		<mat-label>Enter your code</mat-label>
		<input matInput [formControl]="videoCode" maxlength=8>
	</mat-form-field>
	<button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
        <mat-icon (click)="goToVideo()">arrow_right_alt</mat-icon>
    </button>
</div>