<div class="whole-section">
  <div class="left-grid">
    <h1 id="dh-title">DH ONLINE CENTER</h1>
    <h2 id="text-title">Stay safe, prepare for <br>
      thanawya amma with online <br>
      classes with the best teachers</h2>
      <button mat-flat-button color="black" (click)="openForm();">Register now!</button>
  </div>
  <div class="right-grid">
    <img id="man-laptop-image" src="assets/svg/3722303.svg"/>
  </div>
</div>