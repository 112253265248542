import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BitrateOptions } from "@videogular/ngx-videogular/core/index";
import { VgHlsDirective } from '@videogular/ngx-videogular/streaming';
@Component({
  selector: 'video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  @Input('link') embedLink: string;
  @Input('title') title: string;

  @ViewChild(VgHlsDirective) vgHLS : VgHlsDirective;
  hlsBitrates: BitrateOptions[];

  constructor() {
    console.log('Constructing')
  }

  ngOnInit(): void {
    console.log(this.embedLink)
  }

  getBitrates($event) {
    for (let i = 0; i < $event.length; i++) {
      if ($event[i].label == "AUTO") {
        $event[i].label = "Auto";
        continue;
      }
      $event[i].label = $event[i].height.toString()
    }
    this.hlsBitrates = $event;
  }

  setBitrate(option: BitrateOptions) {
    this.vgHLS.setBitrate(option);
  }

}
